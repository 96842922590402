import { useResizeObserver } from "@wojtekmaj/react-hooks";
import type { PDFDocumentProxy } from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { PdfPageMaxWidth } from "../../../core/constants/numerical";
import { ChecklistQuestions, Common, Reports } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useMenu } from "../../../core/store/menu-context";
import { SectionVerticalSpace } from "../../../core/theme/global-styles";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { ReviewReportPdfDto } from "../../../domain/dtos/review-reports/review-report-pdf-dto";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useCheckPrePublishReviewStatus,
    useSubmitAndPrePublishItems,
} from "../../../domain/viewmodels/review-reports/preview-items-viewmodel";
import { useGetReviewReportPdf } from "../../../domain/viewmodels/review-reports/review-report-pdf-viewmodel";
import { BackButton, SaveButton } from "../../atoms/SbButton";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { StartAlignedDiv } from "../answer-summary/AnswerSummaryContainer";

pdfjs.GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.min.mjs";

const options = {
    cMapUrl: "/cmaps/",
    standardFontDataUrl: "/standard_fonts/",
};

const resizeObserverOptions = {};

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
`;

const StyledDocumentContainer = styled.div`
    width: 100%;
    max-width: calc(100% - 2em);
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledPage = styled(Page)`
    margin: 1em 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
`;

const PreviewItemsContainer: React.FC = () => {
    const [urlSearchParams] = useSearchParams();
    const questionSetInstanceId = Number(urlSearchParams.get("questionSetInstanceId"));
    const reviewReportSetId = Number(urlSearchParams.get("reviewReportSetId"));

    const [numPages, setNumPages] = useState<number>();
    const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
    const [containerWidth, setContainerWidth] = useState<number>();

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    const menu = useMenu();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Reports });

    const getReviewReportPdf = useGetReviewReportPdf(
        new ReviewReportPdfDto(questionSetInstanceId || null, reviewReportSetId)
    );
    const submitAndPrePublishItems = useSubmitAndPrePublishItems();
    const checkPrePublishReviewStatus = useCheckPrePublishReviewStatus(questionSetInstanceId);

    useLoader(
        areQueriesLoading([getReviewReportPdf, checkPrePublishReviewStatus]) ||
            isMutationLoading(submitAndPrePublishItems),
        PreviewItemsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Checklists);
        menu.changeActiveDrawerItem(DrawerTitles.Reviews);
    }, []);

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy): void => {
        setNumPages(nextNumPages);
    };

    const handleSubmitAndPrePublishItems = (): void => {
        submitAndPrePublishItems.mutate(questionSetInstanceId, {
            onSuccess: async (_: Response<boolean>) => {
                const params = [
                    createNavigateSearchParameter(
                        "questionSetInstanceId",
                        questionSetInstanceId.toString()
                    ),
                    createNavigateSearchParameter(
                        "reviewReportSetId",
                        reviewReportSetId.toString()
                    ),
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter("messageKey", "SuccessfullyPrePublishedReview"),
                ];

                navigateSearch(
                    `${getPath(DrawerTitles.Reviews)}/question-set-instance-review`,
                    params
                );
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const navigateToViewQuestionSetInstanceReviewDetails = (): void => {
        const params = [
            createNavigateSearchParameter(
                "questionSetInstanceId",
                questionSetInstanceId.toString()
            ),
            createNavigateSearchParameter("reviewReportSetId", reviewReportSetId.toString()),
        ];

        navigateSearch(`${getPath(DrawerTitles.Reviews)}/question-set-instance-review`, params);
    };

    return (
        <>
            {(questionSetInstanceId !== 0
                ? areQueriesSuccessful([getReviewReportPdf, checkPrePublishReviewStatus])
                : isQuerySuccessful(getReviewReportPdf)) && (
                <TextTitledPanel title={t("Preview")}>
                    <StyledContainer>
                        <StyledDocumentContainer ref={setContainerRef}>
                            <Document
                                file={`data:application/pdf;base64,${getReviewReportPdf.data?.file}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                                options={options}
                            >
                                {Array.from(new Array(numPages), (_, index) => (
                                    <StyledPage
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        width={
                                            containerWidth
                                                ? Math.min(containerWidth, PdfPageMaxWidth)
                                                : PdfPageMaxWidth
                                        }
                                    />
                                ))}
                            </Document>
                        </StyledDocumentContainer>
                    </StyledContainer>
                    <SectionVerticalSpace />
                    <StartAlignedDiv>
                        <>
                            {questionSetInstanceId !== 0 &&
                                checkPrePublishReviewStatus.data!.canPrePublishReview && (
                                    <SaveButton
                                        label={`${t("ConfirmAndPrePublish", {
                                            keyPrefix: ChecklistQuestions,
                                        })}`}
                                        onClick={handleSubmitAndPrePublishItems}
                                        type={"button"}
                                    />
                                )}
                            <BackButton
                                onClick={() => {
                                    questionSetInstanceId !== 0
                                        ? navigateToViewQuestionSetInstanceReviewDetails()
                                        : navigate(
                                              `${getPath(
                                                  DrawerTitles.Reviews
                                              )}/${reviewReportSetId}`
                                          );
                                }}
                                label={`${t("Edit", { keyPrefix: Common })}`}
                            />
                        </>
                    </StartAlignedDiv>
                </TextTitledPanel>
            )}
        </>
    );
};

export default PreviewItemsContainer;
